/*
 * @Author: wxb
 * @Date: 2021-06-29 10:34:56
 * @LastEditTime: 2021-06-30 01:49:00
 * @LastEditors: wxb
 * @Description: 会员权限验证
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\utils\authenticate.js
 */

import auth from '@/json/auth.json'
import { getToken } from '@/utils/auth'
export function authenticate(authType, memberType) {
  const obj = {
    flag: false,
    msg: 'login'
  }
  // 根据用户类型和功能权限加载
  if (auth[authType] === 'tourist') {
    // 功能权限为游客则都可以访问
    obj.flag = true
  } else if (auth[authType] === 'ordinaryUser') {
    if (getToken()) {
      // 如果已经登录了则可以进行访问
      obj.flag = true
    } else {
      obj.flag = false
      obj.msg = 'login'
    }
  } else if (auth[authType] === 'vipUser') {
    // 判断当前用户类型，如果不是vip类型用户则提示请加入vip
    if (memberType !== 'vipUser') {
      obj.flag = false
      obj.msg = 'vip'
    } else {
      obj.flag = true
    }
  }
  return obj
}
