/*
 * @Author: wxb
 * @Date: 2021-06-29 10:09:29
 * @LastEditTime: 2021-07-02 12:13:34
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\api\organization\index.js
 */
import request from '@/utils/request'
import qs from 'qs'
/**
 * @name: 获取企业列表
 * @param {type}
 * @return:
 */
export function getCompanyList(query) {
  return request({
    url: '/api/company/qualifyCompare/getCompanyList',
    method: 'get',
    params: query
  })
}
/**
 * @name: 对比企业资质通过企业
 * @test:
 * @msg:
 * @param {type}
 * @return:
 */
export function compareQualify4Company(query) {
  return request({
    url: '/api/company/qualifyCompare/compareQualify4Company',
    method: 'get',
    params: query
  })
}
/**
 * @name: 获取得到资质分类树
 * @test:
 * @msg:
 * @param {type}
 * @return:
 */
export function getInfoTree(query) {
  return request({
    url: '/api/company/qualifyinfo/getInfoTree',
    method: 'get',
    params: query
  })
}
/**
 * @name: 获取得到资质分类列表信息
 * @test:
 * @msg:
 * @param {type}
 * @return:
 */
export function getInfoList(query) {
  return request({
    url: '/api/company/qualifyinfo/getInfoList',
    method: 'get',
    params: query
  })
}
/**
 * @name: 企业资质对比通过资质进行比较不同企业之间的差异
 * @test:
 * @msg:
 * @param {type}
 * @return:
 */
export function compareCompany4Qualify(query) {
  return request({
    url: '/api/company/qualifyCompare/compareCompany4Qualify',
    method: 'get',
    params: query
  })
}
/**
 * @description: 查询企业详情
 * @param {type}
 * @return:
 */
export function showCompanyByID(query) {
  return request({
    url: '/api/company/Mechanism/showCompanyByID',
    method: 'get',
    params: query
  })
}
/**
 * @description: 获取企业资质列表
 * @param {type}
 * @return:
 */
export function getQualifyList4Company(query) {
  return request({
    url: '/api/company/qualifyCompare/getQualifyList4Company',
    method: 'get',
    params: query
  })
}
/**
 * @description: 企业获得奖项列表信息
 * @param {type}
 * @return:
 */
export function getAwardsList(query) {
  return request({
    url: '/api/company/company/getAwardsList',
    method: 'get',
    params: query
  })
}
/**
 * @description: 查看企业的既往项目业绩信息
 * @param {type}
 * @return:
 */
export function getPerformanceListByID(query) {
  return request({
    url: '/api/company/company/getPerformanceListByID',
    method: 'get',
    params: query
  })
}
/**
 * @description: 获取企业联系人信息
 * @param {type}
 * @return:
 */
export function getPersonnelList(query) {
  return request({
    url: '/api/company/company/getPersonnelList',
    method: 'get',
    params: query
  })
}
/**
 * @description: 查看企业的安防展会信息
 * @param {type}
 * @return:
 */
export function getExhibitionList(query) {
  return request({
    url: '/api/company/company/getExhibitionList',
    method: 'get',
    params: query
  })
}

/**
 * @description: 获取企业注册地址
 * @param {type}
 * @return:
 */
export function getRegAddrList(query) {
  return request({
    url: '/api/company/company/getZhcsd',
    method: 'get',
    params: query
  })
}
/**
 * @description: 获取得到企业控制台访问地址
 * @param {type}
 * @return:
 */
export function getCompanyConsole(query) {
  return request({
    url: '/api/company/deliveryCompany/getCompanyConsole',
    method: 'get',
    params: query
  })
}
/**
 * @description: 获取展会名称列表
 * @param {type}
 * @return:
 */
export function getExhibitionNameList(query) {
  return request({
    url: '/api/company/deliveryCompany/getExhibitionNameList',
    method: 'get',
    params: query
  })
}
/**
 * @description: 根据年份获取展会年份
 * @param {type}
 * @return:
 */
export function getExhibitionYearsByName(query) {
  return request({
    url: '/api/company/deliveryCompany/getExhibitionYearsByName',
    method: 'get',
    params: query
  })
}
/**
 * @description: 智建交付管家获取企业列表
 * @param {type}
 * @return:
 */
export function getCompanyList4delivery(params) {
  return request({
    url: '/api/company/Mechanism/getCompanyList',
    method: 'post',
    data: params
  })
}
/**
 * @description: 收藏企业/移动企业到某一标签下
 * @param {type}
 * @return:
 */
export function collectCompany(params) {
  return request({
    url: '/api/company/deliveryCompany/collectCompany',
    method: 'post',
    data: params
  })
}
/**
 * @description: 在某一个收藏标签下移除企业
 * @param {type}
 * @return:
 */
export function removeCompanyFromCollectTags(params) {
  return request({
    url: '/api/company/deliveryCompany/removeCompanyFromCollectTags',
    method: 'post',
    data: params
  })
}
/**
 * @description: 取消收藏企业
 * @param {type}
 * @return:
 */
export function removeCollectCompany(params) {
  return request({
    url: '/api/company/deliveryCompany/removeCollectCompany',
    method: 'post',
    data: params
  })
}
/**
 * @description: 获取标签列表
 * @param {type}
 * @return:
 */
export function getCollectTagsList(query) {
  return request({
    url: '/api/company/deliveryCompany/getCollectTagsList',
    method: 'get',
    params: query
  })
}
/**
 * @description: 添加/修改收藏标签
 * @param {type}
 * @return:
 */
export function addOrEditCollectTags(params) {
  return request({
    url: '/api/company/deliveryCompany/addOrEditCollectTags',
    method: 'post',
    data: params
  })
}
/**
 * @description:删除收藏标签
 * @param {type}
 * @return:
 */
export function deleteCollectTags(params) {
  return request({
    url: '/api/company/deliveryCompany/deleteCollectTags',
    method: 'post',
    data: params
  })
}
/**
 * @description: 获取得到收藏的企业列表
 * @param {type}
 * @return:
 */
export function getCollectCompanyList(query) {
  return request({
    url: '/api/company/deliveryCompany/getCollectCompanyList',
    method: 'get',
    params: query
  })
}
/**
 * @description: 获取企业文档
 * @param {type}
 * @return:
 */
export function getCompanyFiles(params) {
  return request({
    url: '/api/company/LoginMechanism/getFileList',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
/**
 * @description: 获取企业注册人员信息
 * @param {type}
 * @return:
 */
export function getRegisterPerson(query) {
  return request({
    url: '/api/company/deliveryCompany/getRegisterPerson',
    method: 'get',
    params: query
  })
}
