<!--
 * @Author: wxb
 * @Date: 2021-06-30 15:47:10
 * @LastEditTime: 2021-08-10 15:05:24
 * @LastEditors: wxb
 * @Description:登录或者vip提示弹出框
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\components\dialogviporlogin.vue
-->
<template>
  <el-dialog title="提示"
             :visible.sync="dialogVisible"
             width="30%"
             v-dialogDrag>
    <div class="msg-content">
      <div class="msg-content-title">{{tips}}</div>
      <div class="msg-content-tools">
        <el-button type="primary"
                   round
                   v-show="type==='login'"
                   @click="handleClickLogin">去登录</el-button>
        <el-button type="warning"
                   round
                   v-show="type==='vip'"
                   @click="handleOpenVip">开通vip</el-button>
      </div>
      <div class="msg-content-vip-login"
           v-show="type==='vip'">已经有vip账号， <a @click="handleClickLogin">去登录</a>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'dialogviporlogin',
  data() {
    return {
      dialogVisible: false,
      tips: '',
      type: ''
    }
  },
  methods: {
    handleShow(msg) {
      this.dialogVisible = true
      this.type = msg
      if (msg === 'login') {
        this.tips = '登录后，查看更多内容'
      } else if (msg === 'vip') {
        this.tips = '开通vip后，查看更多内容'
      }
    },
    handleClickLogin() {
      localStorage.setItem('sourceurl', this.$route.fullPath)
      this.$router.push({
        name: 'login'
      })
    },
    handleOpenVip() {
      this.$router.push({
        name: 'vip'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.msg-content {
  &-title {
    text-align: center;
    padding: 4rem;
  }

  &-tools {
    text-align: center;
  }

  &-vip-login {
    text-align: center;
    padding: 0.5rem;
    a {
      cursor: pointer;
      color: #409eff;
    }
  }
}
</style>
